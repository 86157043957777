export const queryKeys = {
   patient: ['patient'] as const,
   myClinician: ['my_clinician'] as const,
   myTherapist: ['my_therapist'] as const,
   sitter: ['sitter'] as const,
   params: ['params'] as const,
   programs: ['programs'] as const,
   patientMedications: ['patient_medications'] as const,
   customerVaultData: ['customer_vault_data'] as const,

   // Products
   products: ['products'] as const,
   productsBySku: (skus?: string[]) =>
      ['products_by_sku', ...(skus ?? [])] as const,
   addonProducts: (tags?: number[]) =>
      ['addon_products', ...(tags ?? [])] as const,
   medicalAddonProducts: ['medical_addon_products'] as const,
   followOnProducts: ['follow_on_products'] as const,
   productCategories: ['product_categories'] as const,
   productCategory: (id?: string) => ['product_category', id] as const,
   categoryProducts: (id: string) => ['category_products', id] as const,
   missedAppointmentFeeProduct: ['missing_appointment_fee_product'] as const,
   missedAppointments: ['missed_appointments'] as const,
   missedAppointmentById: (id: string) => ['missed_appointments', id] as const,

   // Appointment bank
   appointmentBank: ['appointment_bank'] as const,
   addons: () => [...queryKeys.appointmentBank, 'addons'] as const,

   // Appointments
   appointments: ['appointments'] as const,
   appointment: (id?: string) => ['appointment', id] as const,
   appointmentDisposition: (id?: string) =>
      ['appointment_disposition', id] as const,
   upcomingAppointments: ['upcoming_appointments'] as const,
   appointmentCapstonePending: ['appointment', 'capstone_pending'] as const,

   // Mood tracking and dosing
   dailyMoods: ['daily_moods'] as const,
   moodTracking: ['mood_tracking'] as const,
   moodTrackingByPeriod: (period: string) =>
      [...queryKeys.moodTracking, period] as const,
   dosingHistory: ['dosing_history'] as const,
   dosageConfirmations: ['dosage_confirmations'] as const,
   weeklyMoodTracking: ['weekly_mood_tracking'] as const,
   weeklyMoodProgress: ['weekly_mood_progress'] as const,
   weeklyMoodProgressAnxiety: () =>
      [...queryKeys.weeklyMoodProgress, 'anxiety'] as const,
   weeklyMoodProgressDepression: () =>
      [...queryKeys.weeklyMoodProgress, 'depression'] as const,
   weeklyMoodProgressPtsd: () =>
      [...queryKeys.weeklyMoodProgress, 'ptsd'] as const,
   weeklyPauseRequiredFlows: ['weekly_pause_required_flows'] as const,
   dosingPlaylists: ['dosing_playlists'] as const,
   dosingSongs: (playlistId?: string) => ['dosing_songs', playlistId] as const,
   adsCounter: ['ads_counter'] as const,

   // Insurance
   insurances: ['insurances'] as const,
   insuranceForState: (state: string) => ['insurances', state] as const,
   insuranceEncounters: (forInsurance: boolean) =>
      ['insurance_encounters', forInsurance] as const,
   programEncounters: () =>
      [...queryKeys.appointmentBank, 'prescriber_encounters'] as const,

   // Payments
   failedPayments: ['failed_payments'] as const,
   failedPaymentById: (id: string) => ['failed_payment', id] as const,
   transactions: ['transactions'] as const,
   transactionById: (transactionId: string) =>
      ['transaction', transactionId] as const,

   // Patient Cdts

   // Scheduling
   serviceSchedulingBounds: (serviceId?: number) =>
      ['service_scheduling_bounds', serviceId] as const,
   stateSchedulingLocations: (state?: string | null) =>
      ['state_scheduling_locations', state] as const,
   eligible_clinicians: (welkinEncounterName?: string) =>
      ['eligible_clinicians', welkinEncounterName] as const,
   dailyAvailability: (
      date?: string,
      staffId?: number | null,
      serviceId?: number | null,
      locationId?: number | null,
   ) => ['daily_availability', date, staffId, serviceId, locationId] as const,
   staffAvailabilityByMonth: (
      locationId: number | null,
      year: number,
      month: number,
      serviceId: number | null,
   ) =>
      [
         'staff_availability_by_month',
         locationId,
         year,
         month,
         serviceId,
      ] as const,
   locationsReport: (staffIds?: string, working?: boolean) =>
      ['locations_report', staffIds, working] as const,
   clinicianAvailabilityByTemplate: (encounterTemplate: string) =>
      ['clinician_availability', encounterTemplate] as const,

   // Program goals
   myProgramGoals: ['my_program_goals'] as const,

   // Patient
   patientLastCarePlanChoice: ['patient_last_care_plan_choice'] as const,

   // Misc
   chatBoostrap: ['chat_bootstrap'] as const,
   contactConsent: ['contact_consent'] as const,

   // Games
   breathingExercises: ['breathing_exercises'] as const,
};
